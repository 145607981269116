import React from "react"
import Layout from "../../../components/layout_en"
import Seo from "../../../components/seo"
import { Link, graphql } from "gatsby"
import {Container, Row, Col, Card, Button} from "react-bootstrap"
import { IoChevronForwardOutline } from "@react-icons/all-files/io5/IoChevronForwardOutline";
/*import CONFIG from '../../global-vars-trezo.js'*/
import { GatsbyImage } from "gatsby-plugin-image";
import trezoPlan from "../../../images/tree.svg"





const Previsions = ({data}) => (
    <Layout>
        <Seo
            titleTemplate={`Trezorino : Financial planning, budgeting, and forecasting software | Trezorino`}
            title="Project your cash flow into the future in real time and identify risks and opportunities."
            description="Project your cash flow into the future in real time and identify risks and opportunities."
            image={trezoPlan}
            lang="en"
        />

        <div className="">
            <Container>
                {data.first.edges.map(document => (
                    <Row className="home-box-header justify-content-center" key={document.node.id} >
                        <Col className="my-auto home-box-text order-2 order-sm-1 order-lg-1">

                            <h1 className="title">Cash flow forecasting becomes easy.</h1>
                            <p className="section">With trezorino, you can plan the financial future of your organization, perform a credible analysis, make the right decisions at the right time and avoid bad surprises.</p>
                            {/*<p className="section">En quelques clics seulement vous pouvez définir vos budgets prévisionnels pour chaque catégorie et constater si la situation financière de votre entreprise sera viable à court moyen ou long terme.</p>
                            */}


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Request demo</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Start free trial</Button>
                                </Col>
                            </Row>

                        </Col>

                        <Col className="home-box-image order-1 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {/*  {section1.node.video &&

                            <video className="box-shadow" key={section1.node.video.id} src={section1.node.video.url} width="100%" height="100%" loop muted playsInline autoPlay></video>
                        }*/}

                            {document.node.Image480?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} className="" key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container className="container-blue-inverse container-line" >
                {data.second.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.Image480?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">A detailed analysis of your cash flow forecast</h2>
                            <p className="section-h2">Instantly identify overspending and ensure business objectives are met.</p>
                            <p className="section-h2">By analyzing the differences between actual and budget, you can develop new action plans with your teams and, if necessary, adjust your forecasts according to the context.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Request demo</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Start free trial</Button>
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container className="container-line" >
                {data.trois.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="my-auto home-box-text order-1 order-sm-1 order-lg-1">

                            <h2 className="title">The power of forecasting scenarios</h2>
                            <p className="section-h2">You can anticipate every eventuality with a clear plan and face the future more serenely.</p>
                            <p className="section-h2">Create your cash flow scenarios to calibrate your needs according to the chosen assumptions. Compare your different situations and choose the strategy that will guarantee your growth.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Request demo</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Start free trial</Button>
                                </Col>
                            </Row>

                        </Col>
                        <Col className="home-box-image order-2 order-sm2 order-lg-2 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.Image480?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container className="container-blue container-line" >
                {data.cinq.edges.map(document => (
                    <Row className="justify-content-center" key={document.node.id}>
                        <Col className="home-box-image order-1 order-sm1 order-lg-1 d-flex align-items-center justify-content-center" xs={12} sm={12} lg={7} xl={7} md={7}>

                            {document.node.MainImage?.map( image=> (
                                <GatsbyImage fluid={image.localFile.childImageSharp.fluid} key={image?.id} image={image?.localFile.childImageSharp.gatsbyImageData} alt=""/>
                            ))}

                        </Col>
                        <Col className="my-auto home-box-text order-2 order-sm-2 order-lg-2">

                            <h2 className="title">Synchronize your business applications</h2>
                            <p className="section-h2">Enrich your forecasts with your outstanding invoices from your billing or accounting applications.</p>
                            <p className="section-h2">Trezorino's notification system allows you to better control your supplier payment cycles and anticipate customer delays to preserve your cash flow.</p>


                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button variant="demo"  style={{width:"100%"}} href="/en/request-demo/">Request demo</Button>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={6} className="left justify-content-center pt-3">
                                    <Button  variant="try" style={{width:"100%"}} href={`${process.env.APP_URL}/signup`}>Start free trial</Button>
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                ))}
            </Container>
            <Container >

                    <Row className="justify-content-center pb-4 pt-5">
                        <h2 className="center message-title">Do always more with Trezorino.</h2>
                    </Row>


                <Row xs={2} sm={2} md={2} lg={4} xl={4} className="g-4">
                    {data.allStrapiFooterMenus.edges.map(menu => (
                        <Card className="card-box" key={menu.node.id}>
                            {menu.node.MainImage.map( ImageCard=> (
                                <GatsbyImage key={ImageCard.id}  image={ImageCard?.localFile.childImageSharp.gatsbyImageData} alt="text a définir"/>
                            ))}
                            <Card.Body>
                                <Card.Title className="center card-title" >{menu.node.LinkText}</Card.Title>
                            </Card.Body>
                            <Card.Footer className="justify-content-center card-footer">
                                <Link to={"/en"+menu.node.LinkHref+"/"}  className="card-link-footer">learn more<IoChevronForwardOutline></IoChevronForwardOutline></Link>
                                {/* <Button variant="primary" className="collab-button-footer-card"  href={"/fr"+menu.node.LinkHref}  >Essai gratuit</Button> */}
                            </Card.Footer>
                        </Card>
                    ))}
                </Row>

                <Row className="justify-content-md-center pb-5 pt-5">
                </Row>

            </Container>
        </div>
    </Layout>

)

export default Previsions

export const data = graphql`  
query previsions_en {
  first : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_1"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  second : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_2"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  trois : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_3"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  quatre : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_4"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  cinq : allStrapiFeaturesPrevisions(filter: {id: {eq: "Features-previsions_4"}}){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  all : allStrapiFeaturesPrevisions(
    filter: {id: {ne: "Features-previsions_1"}}
    sort: {order: ASC, fields: Order}
  ){
    edges {
      node {
        id
        ShowTryLink
        Title
        Description
        Order
        AltImage
        video {
          id
          url
        }
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image480 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image768 {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
        Image992 {
          id
          url
             localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
      }
    }
  }
  allStrapiFooterMenus (
  sort: {order: ASC, fields: Order}
  ){
    edges {
      node {
        id
        Title
        LinkText
        LinkHref
        Order
        MainImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
  
      }
    }
  }
  allStrapiTitleMenuFooter {
    edges {
      node {
        id
        Title
      }
    }
  }
}
`
